<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Stampe</v-toolbar-title>
    </v-toolbar>
    <v-expansion-panels>
      <report v-for="(item, index) of stampe.filter(x => x.nomeReport)" :key="index" :report="item"/>
    </v-expansion-panels>
  </v-card>
</template>

<script>
  import configurazioni from '@/services/configurazioniService'
  import Report from './report'

  export default {
    components: {
      Report
    },
    data: () => ({
      stampe: []
    }),
    async mounted() {
      let currentDevice = this.$store.getters['mobile/device']
      this.stampe = await configurazioni.getConfigurazione(currentDevice.base, 'mobile', 'report')
    }
  }
</script>
